<template>
	<div>
		<CustomTable
            id_table="semence_flux_emplacement"
            ref="table"
            :items="emplacements"
            :busy.sync="table_busy"
            primaryKey="emplacementcongelation_id"
            :hide_if_empty="true"
        />
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"

	export default {
		name: 'EmplacementDetail',
		mixins: [Congelation],
		props: ['emplacement'],
		data () {
			return {
				table_busy: false,
				emplacements: []
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.table_busy = true
				this.emplacements = await this.getFluxEmplacement(this.emplacement.emplacementcongelation_id)
				this.table_busy = false
			},
		},

		components: {
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable')
		}
	}

</script>